<template>
  <div class="pa-0 ma-0">
    <v-toolbar elevation="0" outlined class="pa-0 ma-0" dense height="none">
      <v-container fluid class="pa-0 ma-0">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="12" md="8">
            <v-row
              :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
              no-gutters
              align="center"
            >
              <v-divider vertical class="ml-1 mr-1" style="height: 48px">
              </v-divider>
              <v-col cols="5" md="3" align-self="center">
                <v-select
                  ref="sel1"
                  color="reform_primary"
                  hide-details
                  item-color="reform_primary"
                  :items="items"
                  single-line
                  flat
                  solo
                  dense
                  @change="setCur"
                  v-model="cur_c"
                ></v-select>
              </v-col>
              <v-divider vertical class="ml-1 mr-1" style="height: 48px">
              </v-divider>
              <v-col cols="5" md="3" align-self="center">
                <v-select
                  ref="sel2"
                  v-if="subOn"
                  hide-details
                  flat
                  solo
                  dense
                  color="reform_primary"
                  item-color="reform_primary"
                  :items="sub"
                  single-line
                  @change="setSub"
                  v-model="subcur_c"
                ></v-select>
              </v-col>
              <v-divider vertical class="ml-1 mr-1" style="height: 48px">
              </v-divider>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <router-view @set-bar="setStatus" />
  </div>
</template>

<script>
export default {
  data: () => ({
    cur_c: "",
    subcur_c: "의사 검색",
    subOn: false,
    items: ["회사소개", "DrRefer 서비스 소개", "공지사항", "문의사항"],
    sub: ["의사 검색", "의뢰 발신", "의뢰 수신", "소개페이지"],
  }),
  mounted() {
    this.setParam();
  },

  methods: {
    setCur() {
      if (this.cur_c === this.items[1]) this.subOn = true;
      else this.subOn = false;

      switch (this.cur_c) {
        case this.items[0]:
          this.$router.push({ name: "company" }).catch(() => {});
          break;
        case this.items[1]:
          this.$router
            .push({
              path: "/v/introduce/service/1",
            })
            .catch(() => {});
          break;
        case this.items[2]:
          this.$router
            .push({
              name: "NoticeList",
            })
            .catch(() => {});
          break;
        case this.items[3]:
          this.$router.push({ name: "helpdesk" }).catch(() => {});
          break;

        default:
          break;
      }
    },
    setSub() {
      switch (this.subcur_c) {
        case this.sub[0]:
          this.$router
            .push({
              path: "/v/introduce/service/1",
            })
            .catch(() => {});
          break;
        case this.sub[1]:
          this.$router
            .push({
              path: "/v/introduce/service/2",
            })
            .catch(() => {});
          break;
        case this.sub[2]:
          this.$router
            .push({
              path: "/v/introduce/service/3",
            })
            .catch(() => {});
          break;
        case this.sub[3]:
          this.$router
            .push({
              path: "/v/introduce/service/4",
            })
            .catch(() => {});
          break;

        default:
          break;
      }
    },
    setParam() {
      let path = this.$route.path;
      this.subOn = false;
      if (path.includes("company")) this.cur_c = this.items[0];
      else if (path.includes("notice")) this.cur_c = this.items[2];
      else if (path.includes("helpdesk")) this.cur_c = this.items[3];
      else if (path.includes("service")) {
        this.cur_c = this.items[1];
        this.subOn = true;
        if (path.includes("1")) this.subcur_c = this.sub[0];
        else if (path.includes("2")) this.subcur_c = this.sub[1];
        else if (path.includes("3")) this.subcur_c = this.sub[2];
        else if (path.includes("4")) this.subcur_c = this.sub[3];
      }
    },
    setStatus(param) {
      this.cur_c = param;
    },
  },
};
</script>

<style>
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
